import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Banner from "./components/Banner";
import Dropdown from "./components/Dropdown";
import GetQuoteButton from "./components/GetQuoteButton";
import Grid from "./components/Grid";
import Header from "./components/Header";
import "./homepage.css";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sectors = [
    {
      title: "Construction",
      text: "Aerial Maps of the construction progress, 3D models, Land Topography  and much more!",
      image: "homepage-imgs/construction.png",
      sampleMaps: true,
    },
    {
      title: "Real Estate",
      text: "Accurate survey, Aerial Mapping, 3D view, Land Topography and more!",
      image: "homepage-imgs/real-estate.jpg",
      sampleMaps: true,
    },
    {
      title: "Power line inspection",
      text: "3D modeling of towers, and land topography",
      image: "homepage-imgs/powerline.jpg",
      sampleMaps: false,
    },
    {
      title: "Agriculture",
      text: "Drone mapping for digital agriculture, topography, insightful information about the land",
      image: "homepage-imgs/agriculture.jpg",
      sampleMaps: false,
    },
    {
      title: "Natural Disaster",
      text: "Providing before and after images of natural disasters",
      image: "homepage-imgs/public-safety.jpg",
      sampleMaps: false,
    },
  ];

  const images = [
    // {
    //   title: "Progressive Construction Images",
    //   image: "homepage-imgs/before-after.jpg",
    //   sampleMaps: false,
    // },
    {
      title: "Aerial Mapping",
      image: "homepage-imgs/map-overlay.png",
      sampleMaps: false,
    },
    {
      title: "3D Overview",
      image: "homepage-imgs/3dmap1.png",
      sampleMaps: false,
    },
    {
      title: "Topography",
      image: "homepage-imgs/topo.jpg",
      sampleMaps: false,
    },
    {
      title: "Contour Mapping",
      image: "homepage-imgs/contour-mapping.webp",
      sampleMaps: false,
    },
  ];

  const desctiptions = [
    <>
      <span className="descriptionTitle">FOR</span>
      <div className="descriptionContent">
        Real Estate, Builders, Construction, Property owners, Land Owners,
        Architects, Industry Planners
      </div>
    </>,
    <>
      <span className="descriptionTitle">FOR</span>
      <div className="descriptionContent">
        Hotels, Resorts, Home Stays, Amusement Parks, Educational Institutions,
        Industries, Hospitals, Apartment Complexes, Villas, etc
      </div>
    </>,
  ];

  return (
    <>
      <Header
        title={"AERIAL DRONE MAPPING"}
        description={
          <>
            Accurate, high resolution, survey maps of your property for easy
            planning of Layouts, Resorts, Industries, Road Formation, <br />
            Hospitals, Apartments and more
          </>
        }
      />
      <GetQuoteButton />

      {/* Packages */}
      <div className="packagesTitleContainer">
        <div>
          <span className="packagesTitle">CUSTOMISED SERVICES</span>
        </div>
      </div>
      <div className="dropDown" id="packages">
        <div className="dropDownAgain">
          <Dropdown
            title={
              <>
                BOOST YOUR BUSINESS, <br /> INCREASE VISIBILITY
              </>
            }
            description={desctiptions[1]}
            package={"package-one"}
          />
          <Dropdown
            title={"PLANNING AND VISUALISATION"}
            description={desctiptions[0]}
            package={"package-two"}
          />

          <Dropdown
            title={"ALL-IN-ONE"}
            description={(desctiptions[0], desctiptions[1])}
            // package={"package-three"}
          />
        </div>
      </div>

      {/* sectors we cater to */}
      {/* <Grid heading="SECTORS WE CATER TO" gridItems={sectors} /> */}
      <Banner text="DRONE MAPPING FOR FASTER AND SAFER OPERATIONS WITH MAXIMUM RETURNS" />
      <Grid
        heading="MAKE BETTER DECISIONS WITH ACCURATE ORTHOPHOTOS AND 3D MAPS"
        gridItems={images}
      />

      {/* 3d view of quarry */}
      <div className="homepage3dQuarryContainer">
        <div className="homepage3dQuarryWrapper">
          <span className="homepage3dQuarryTitle">3D VIEW OF A QUARRY</span>
          <iframe
            className="homepage3dQuarryImage"
            title="Quarry"
            frameBorder="0"
            src="https://hub.dronedb.app/r/curran/trial/view/cG9pbnRjbG91ZC9tZXJnZWQubGF6/pointcloud?embed=1"
          >
            {" "}
          </iframe>
        </div>
      </div>

      {/* contact us */}
      <div className="contactUsContainer">
        <span className="homepage3dQuarryTitle">Contact us</span>
        <div className="contactUsWrapper">
          <div className="contactUsInfoContainer">
            <div className="contactUsInfoWrapper">
              <span className="contactUsTitle">Hello!</span>
              <span className="contactUsText">
                We'd love to hear from you! Have a question about the services
                we provide? Send us the details on the type of project you're
                looking to hire for, and we'll be happy to get back to you with
                a free quote and information.
              </span>
              <a href="https://wa.link/97shfk">
                <button className="contactUsButton">
                  Message us on Whatsapp!
                </button>
              </a>
              <a href="tel: +916361438367">
                <button className="contactUsButton">Call us directly!</button>
              </a>
            </div>
            <iframe
              title="google map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.342065448663!2d77.55865131490549!3d12.949949890870057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e1deb44965d%3A0x6e9b8de62b6e8cc8!2sBespoke%20Atelier!5e0!3m2!1sen!2sin!4v1673611578643!5m2!1sen!2sin"
              width="600"
              height="450"
              className="googleMaps"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Homepage;
