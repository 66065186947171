import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";

import Faqs from "./pages/faqs/Faqs";
import GetQuote from "./pages/get-quote/GetQuote";
import GoogleImagery from "./pages/google-imagery/GoogleImagery";
import Homepage from "./pages/homepage/Homepage";
import PackageOne from "./pages/packageOne/PackageOne";
import PackageThree from "./pages/packageThree/PackageThree";
import PackageTwo from "./pages/packageTwo/PackageTwo";
import SampleDroneMaps from "./pages/sample-done-maps/SampleDroneMaps";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/sample-drone-maps" element={<SampleDroneMaps />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/get-quote" element={<GetQuote />} />
          <Route path="/google-imagery" element={<GoogleImagery />} />
          <Route path="/package-one" element={<PackageOne />} />
          <Route path="/package-two" element={<PackageTwo />} />
          <Route path="/package-three" element={<PackageThree />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
