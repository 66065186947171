import React from "react";
import Navbar from "../../../components/Navbar";

const Header = (props) => {
  return (
    <>
      <Navbar page="homepage" />
      <div className="homepageWrapper">
        <img
          className="homepageDroneBgImage"
          src="homepage-imgs/drone-bg.webp"
          alt="Drone"
        />

        <div className="homepageHeaderContent">
          <span className="homepageHeaderContentTitle">{props.title}</span>

          <span className="homepageHeaderContentText">{props.description}</span>
          {/* <span
            className="homepageHeaderContentText"
            style={{
              // textAlign: "center",
              fontWeight: "800",
              fontSize: "30px",
              marginTop: "20px",
              width: "100%",
            }}
          >
            FLY NOW PAY LATER*
          </span> */}
        </div>
      </div>
    </>
  );
};

export default Header;
