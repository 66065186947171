import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import GetQuoteButton from "../homepage/components/GetQuoteButton";
import GridItem from "../homepage/components/GridItem";
import Faq from "./components/Faq";
import "./faqs.css";

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const faqs = [
    {
      question: "What is an Orthomosaic map?",
      answer:
        "An orthomosaic is a large map with high detail and resolution made by combining many smaller orthophotos. These photos are taken by special purpose drones which takes multiple images of the same area in many different angles which is later stitched together and made into a highly accurate map using complex algorithms",
    },
    {
      question: "What is an Orthophoto?",
      answer:
        "The aerial photos taken by a drone or satellite which are geometrically corrected such that the scale is uniform and the photo follows a given map projection.",
    },
    {
      question: "What is Photogrammetry?",
      answer:
        "The science, and technology of obtaining reliable information such as the height, depth, width, area, volume of physical objects and the environment like trees, hills through processes of analyzing the images taken our drone ",
    },
    {
      question:
        "What is the difference between an Aerial Photo and an Orthomosaic map?",
      answer:
        "Aerial photos are just photographs, hence they are not accurate to scale and offer a perspective view of a given area and can't be used to measure where as an Orthomosaic map is a map made by using complex technology which is as accurate as an actual map",
    },
  ];

  const images = [
    "faq-imgs/Ortho1.png",
    "faq-imgs/Ortho2.jpg",
    "faq-imgs/Ortho3.jpeg",
    "faq-imgs/Ortho4.jpg",
  ];
  return (
    <div>
      <Navbar />
      <GetQuoteButton />
      {/* faqs */}
      <div className="faqTitleContainer">
        <div className="faqTitle">FREQUENTLY ASKED QUESTIONS</div>
        <div className="faqTitleText">
          Please reach us at{" "}
          <a href="tel: +916361438367">
            <button className="faqContactUsButton">6361 438367</button>
          </a>
          if you cant find an answer to your question
        </div>
      </div>

      <div className="faqContainer">
        <div className="faqWrapper">
          {faqs.map((faq) => (
            <Faq question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>

      {/* photo gallery */}
      <div className="faqPhotoGalleryContainer">
        <span className="titleText">Photo Gallery</span>
        <div className="faqPhotos">
          {images.map((image) => (
            <div className="faqPhoto">
              <GridItem page={"faq"} gridItemImage={image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
