import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

const Dropdown = (props) => {
  let bgc;
  if (props.package === "package-two")
    bgc = { backgroundImage: "linear-gradient(135deg, #584383a4, #584383d3)" };
  const description = (
    <div className="dropDownDescriptionContainer">
      <span className="dropDownDescription">{props.description}</span>
      <hr className="dropdownDivider" />
      <NavLink component={Link} to={`/${props.package}`}>
        <button className="viewMore">VIEW MORE</button>
      </NavLink>
    </div>
  );

  return (
    <div style={bgc} className="dropDownContainer">
      <NavLink
        style={{ textDecoration: "none" }}
        component={Link}
        to={`/${props.package}`}
      >
        <div className="dropDownWrapper">
          {/* drop down title */}
          <div className="dropDownTitleContainer">
            <span className="dropDownTitle">{props.title}</span>
            {/* <span className="arrowDown">▼</span> */}
          </div>
          <div className="dropdownDivinderContainer">
            <hr className="dropdownDivider" />
          </div>
          {/* drop down answer */}
          {description}
        </div>
      </NavLink>
    </div>
  );
};

export default Dropdown;
