import { useState } from "react";

const Faq = (props) => {
  const handleFaqClick = () => {};

  const [faqClicked, setFaqClicked] = useState(false);

  const faqAnswer = (
    <div>
      <div className="faqAnswerContaier">
        <span className="faqAnswer">{props.answer}</span>
      </div>
    </div>
  );

  return (
    <div>
      <div className="faqQuestionContainer">
        <span
          onClick={() => setFaqClicked(!faqClicked)}
          className="faqQuestion"
        >
          {props.question}
        </span>
        <span
          onClick={() => setFaqClicked(!faqClicked)}
          className="faqQuestionArrow"
        >
          ▼
        </span>
      </div>
      {faqClicked && faqAnswer}
      <hr className="faqHr" />
    </div>
  );
};

export default Faq;
