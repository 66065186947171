import React from "react";

const GridItem = (props) => {
  let className = props.page === "faq" ? "reduceWidth" : "";

  return (
    <div className={"homepageGridItem " + className}>
      <span className="homepageGridItemTitle">{props.gridItemTitle}</span>
      <img src={props.gridItemImage} alt="" className="homepageGridItemImage" />
      <span className="homepageGridItemText">{props.gridItemText}</span>
      {props.sampleMaps && (
        <a href="/sample-drone-maps">
          <button className="viewSampleMapsButton blackButton">
            VIEW SAMPLE MAPS
          </button>
        </a>
      )}
    </div>
  );
};

export default GridItem;
