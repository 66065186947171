import React from "react";
import GridItem from "./GridItem";

const Grid = (props) => {
  return (
    <>
      <div className="homepageGridContainer">
        <span className="homepageGridTitle">{props.heading}</span>
        <div className="homepageGridWrapper">
          {props.gridItems.map((gridItem) => (
            <GridItem
              gridItemTitle={gridItem.title}
              gridItemText={gridItem.text}
              gridItemImage={gridItem.image}
              sampleMaps={gridItem.sampleMaps}
              key={gridItem.title}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Grid;
