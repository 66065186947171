import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import GetQuoteButton from "../homepage/components/GetQuoteButton";
import GridItem from "../homepage/components/GridItem";
import Image from "./components/Image";

import "./sampleDroneMaps.css";
const SampleDroneMaps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    {
      imageTitle: "Google Maps",
      image: "sample-drone-maps-imgs/google-map-old.png",
    },
    {
      imageTitle: "Outline of Drone Shoot",
      image: "sample-drone-maps-imgs/outline-drone-shot.png",
    },
    {
      imageTitle: "Drone Map Overlayed on Google Maps",
      image: "sample-drone-maps-imgs/drone-map-high-res.png",
    },
    {
      imageTitle: "3D View of The Land (Shot by drone)",
      image: "homepage-imgs/3dmap1.png",
    },
  ];

  const dataImages = [
    {
      imageTitle: "Terrain",
      image: "sample-drone-maps-imgs/terrain-overlay.png",
    },
    {
      imageTitle: "Topography",
      image: "sample-drone-maps-imgs/topo-overlay.png",
    },
    {
      imageTitle: "Elevation on the Land",
      image: "sample-drone-maps-imgs/elevation-overlay.png",
    },
  ];
  return (
    <div>
      <Navbar page="sampleDroneMaps" />
      <GetQuoteButton />
      <div className="sampleDroneMapsContainer">
        <span className="titleText">Sample Drone Maps (33 Acres)</span>
        <div className="sampleDroneMapsImagesContainer">
          {images.map((image) => (
            <Image
              key={image.imageTitle}
              imageTitle={image.imageTitle}
              image={image.image}
            />
          ))}
        </div>

        <span className="titleText">Data Collected By Drone</span>
        <div className="sampleDroneMapsGrid">
          {dataImages.map((dataImage) => (
            <GridItem
              gridItemTitle={dataImage.imageTitle}
              gridItemImage={dataImage.image}
              key={dataImage.imageTitle}
            />
          ))}
        </div>
        <Image
          imageTitle="Accurately measure any area on the Map"
          image="sample-drone-maps-imgs/area.png"
        />

        <div className="sampleDroneMapsMeasurements">
          <span className="titleText">
            Accurate Area & volume of Water Tank
          </span>
          <div className="measurementsContainer">
            <div className="measurement">
              <img
                src="sample-drone-maps-imgs/areaAndVolumeOfWaterTank.png"
                alt=""
                className="measurementImage"
              />
              <span className="measurementText">
                <b>Area: </b>37.92 Sq Meters <br />
                <b>Volume: </b>25.09 Cubic Meters / <b>25,090 Liters</b>
              </span>
            </div>
            <div className="measurement">
              <img
                src="sample-drone-maps-imgs/depth.png"
                alt=""
                className="measurementImage"
              />
              <span className="measurementText">
                <b>Lenght: </b>4.78m (15.69ft) <br />
                <b>Width: </b> 7.49m (24.7ft) <br />
                <b>Depth: </b>0.7m (2.3ft)
              </span>
            </div>
          </div>
        </div>

        <Image
          imageTitle="Height profile of the land"
          image="sample-drone-maps-imgs/height-profile.png"
        />
      </div>
    </div>
  );
};

export default SampleDroneMaps;
