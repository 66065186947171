import React from "react";

const Card = (props) => {
  return (
    <div className="cardWrapper">
      <img src={props.image} alt="" className="cardImage" />
      <hr className="cardDivider" />
      <span className="cardTitle">{props.title}</span>
      <span className="cardDescription">{props.description}</span>
    </div>
  );
};

export default Card;
