import React from "react";

const Banner = (props) => {
  return (
    <div className="homepageBannerContainer">
      <div className="homepageBannerWrapper">
        <img
          src="homepage-imgs/construction.png"
          alt="construction"
          className="homepageBannerImage"
        />
        <span className="homepageBannerText">{props.text}</span>
      </div>
    </div>
  );
};

export default Banner;
