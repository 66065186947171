import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import GetQuoteButton from "../homepage/components/GetQuoteButton";

import "./getQuote.css";
const GetQuote = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <GetQuoteButton />
      <div className="getQuoteContainer">
        <div className="getQuoteWrapper">
          <span className="getQuoteTitle">
            FOR US TO GIVE YOU AN ACCURATE QUOTE, WE WILL NEED YOUR LAND /
            PROPERTY BOUNDARY AND AREA EXPORTED THROUGH GOOGLE EARTH. PLEASE
            FOLLOW THE STEPS BELOW OR WATCH THE VIDEO FOR A DETAILED
            WALKTHROUGH.
          </span>
          <ol className="getQuoteInstuctionList">
            <li> LOCATE YOUR LAND / PROPERTY ON GOOGLE EARTH</li>
            <li>MARK THE BOUNDARIES OF YOUR LAND / PROPERTY</li>
            <li>EXPORT THE MARKED BOUNDARY AS A KML FILE</li>
            <li>EMAIL OR WHATSAPP THE KML FILE TO US</li>
          </ol>
          <ul className="getQuoteContact">
            <li>Email: contact@dronemaps.in</li>
            <li>Whatsapp: 63614 38367</li>
          </ul>
          <div className="contactButton">
            <a href="https://wa.link/97shfk">
              <button className="contactUsButton">Whatsapp Us</button>
            </a>
            <a href="tel: +916361438367">
              <button className="contactUsButton">Call us</button>
            </a>
          </div>
          <hr className="getQuoteHr" />
          <div className="getQuoteVideo">
            <span className="getQuoteVideoText">
              How to send us your land / property boundary and area
            </span>
            <video
              className="video"
              src="get-quote/kml-file-export.mp4"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetQuote;
