import React from "react";
import { NavLink, Link } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footerContainer">
      <a href="tel:+916361438367" className="footerCallButtonContainer">
        <button className="footerCallButton">Get in touch!</button>
      </a>
      <div className="footerWrapper">
        <NavLink className="footerNavLinkContainer" component={Link} to="/">
          <span className="footerNavLink">Home</span>
        </NavLink>
        <NavLink
          className="footerNavLinkContainer"
          component={Link}
          to="/sample-drone-maps"
        >
          <span className="footerNavLink">Sample Drone Maps</span>
        </NavLink>
        <NavLink className="footerNavLinkContainer" component={Link} to="/faqs">
          <span className="footerNavLink">FAQs</span>
        </NavLink>
        <NavLink
          className="footerNavLinkContainer"
          component={Link}
          to="/get-quote"
        >
          <span className="footerNavLink">Get Quote</span>
        </NavLink>
        <NavLink
          className="footerNavLinkContainer"
          component={Link}
          to="/google-imagery"
        >
          <span className="footerNavLink">Update Google Maps</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
