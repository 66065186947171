import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../homepage/components/Header";

import { Document, Page } from "react-pdf";
import GetQuoteButton from "../homepage/components/GetQuoteButton";

const PackageTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const images = [
    {
      imageTitle: "Google Maps",
      image: "/sample-drone-maps-imgs/google-map-old.png",
    },
    // {
    //   imageTitle: "Outline of Drone Shoot",
    //   image: "sample-drone-maps-imgs/outline-drone-shot.png",
    // },
    {
      imageTitle: "Drone Map Overlayed on Google Maps",
      image: "sample-drone-maps-imgs/drone-map-high-res.png",
    },
    // {
    //   imageTitle: "3D View of The Land (Shot by drone)",
    //   image: "homepage-imgs/3dmap1.png",
    // },
  ];

  const dataImages = [
    {
      imageTitle: "Terrain",
      image: "sample-drone-maps-imgs/terrain-overlay.png",
    },
    {
      imageTitle: "Topography",
      image: "sample-drone-maps-imgs/topo-overlay.png",
    },
    {
      imageTitle: "Elevation on the Land",
      image: "sample-drone-maps-imgs/elevation-overlay.png",
    },
  ];

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Header
        title="Aerial Survey"
        description={
          <>
            Normal surveys take a lot of time and man power <br />
            Sometimes, there are obstiacles like trees, boulders, forests which
            makes land surveys very difficult
            <br />
            Getting the natural floura fauna, land elevation is again very time
            consuming
          </>
        }
      />
      <GetQuoteButton />
      <div className="packageOneContainer">
        <div className="packageOneWrapper">
          <span className="packageOneTitle">This is where we come in.</span>
          <div className="packageContentContainer">
            <div className="packageContentWrapper">
              <span className="packageContentText">
                Using state of the art UAV technology, we fly over the required
                area taking 100/s of overlapping images at different angles.
                Later it is processed and ortho corrected to remove all the
                distortions caused by terrain relief and camera tilts to gives
                us a very high resolution, ortho map.
                <br /> Using photogrammetry, this orthophoto, we can then and
                measure the elevation, topography and Terrain or the land. With
                this we will also be able to get accurate measurements of any
                structure on the land, calculate area and volume to structures,
                the height profile of the land
              </span>
            </div>
          </div>
          <span className="title">Data collected from our aerial flight</span>
          <div className="pdfContiner">
            <iframe
              title="DronePdf"
              src="https://drive.google.com/file/d/1FWnOXBvC55foZX3f6_wKn1wcQ27Vx9gJ/preview?usp=share_link"
              // src="https://pdfhost.io/v/9.S~AtC0Z_Drone_Mapping_Brochure"
              frameborder="0"
              width="90%"
              height="900px"
            ></iframe>
          </div>
          <a
            className="pdfDownload"
            href="/packages-imgs/DroneMaps_Brochure.pdf"
          >
            Click Here to Download Our Brochure
          </a>
          <button className="contactUs">Whatsapp us now!</button>
          <NavLink component={Link} to={"/"}>
            <button className="viewOthers">View Our Other Plans</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PackageTwo;
