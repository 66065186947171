import React from "react";

const Iframe = (props) => {
  return (
    <div className="iFrameContainer">
      <div className="iFrameWrapper">
        <span className="iFrameTitle">{props.title}</span>
        <iframe title={props.title} className="iFrameMap" src={props.link}></iframe>
      </div>
    </div>
  );
};

export default Iframe;
