import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import GetQuoteButton from "../homepage/components/GetQuoteButton";
import Iframe from "./components/Iframe";

import "./googleImagery.css";

const GoogleImagery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const iframes = [
    {
      title: "ORIGINAL LOW RESOLUTION GOOGLE MAP OF A QUARRY",
      link: "https://www.google.com/maps/d/u/1/embed?mid=1TSUyvTZE30gO9W5Pnq2OaAKcpXv3TUQ&ehbc=2E312F",
    },
    {
      title: "AFTER UPDATING HIGH RESOLUTION DRONE MAP ON GOOGLE MAP ",
      link: "https://hub.dronedb.app/r/curran/trial/view/b3J0aG8vb2RtX29ydGhvcGhvdG8udGlm/map",
    },
    {
      title: "LOW RESOLUTION GOOGLE MAP OF THITTAHALLI LAYOUT",
      link: "https://www.google.com/maps/d/u/1/embed?mid=1m0CzKWfVgO_3hL7DH11J6qK_Gk956zo&ehbc=2E312F",
    },
    {
      title: "HIGH RESOLUTION DRONE MAP OF THITTAHALLI ADDED TO GOOGLE MAP",
      link: "https://hub.dronedb.app/r/curran/trial/view/TmV3IE1hcCAxL29kbV9vcnRob3Bob3RvLnRpZg==/map",
    },
    {
      title: "OLD AND OUTDATED GOOGLE MAP OF KSHEMAVANA",
      link: "https://www.google.com/maps/d/u/1/embed?mid=1Xeqe4rDskc-LeU5QqU37LZbKBg9w818&ehbc=2E312F",
    },
    {
      title: "UP TO DATE DRONE MAP OF KSHEMAVANA ADDED TO GOOGLE MAPS",
      link: "https://hub.dronedb.app/r/curran/trial/view/b3J0aG8vb2RtX29ydGhvcGhvdG9fS3NoZW1hdmFuYS50aWY=/map",
    },
  ];
  return (
    <div>
      <Navbar />
      <GetQuoteButton />
      <div className="googleImageryHeaderContainer">
        <div className="googleImageryHeaderWrapper">
          <span className="googleImageryHeaderTitle">
            HOW TO UPDATE GOOGLE MAPS
          </span>
          <span className="googleImageryHeaderText">
            {" "}
            Images on Google maps are generally several years old and are
            low-resolution. This leads to confusion when you link your location
            on your website and your customers don't see the latest additions
            and improvements you have made to your property. We can update your
            location image with the latest, Hi-resolution image.
          </span>
        </div>
      </div>
      {iframes.map((iframe) => (
        <Iframe title={iframe.title} link={iframe.link} />
      ))}
    </div>
  );
};

export default GoogleImagery;
