import React from "react";

const Image = (props) => {
  return (
    <div className="sampleDroneMapsImageWrapper">
      <span className="sampleDroneMapsImageTitle">{props.imageTitle}</span>
      <img src={props.image} alt="" className="sampleDroneMapsImage" />
    </div>
  );
};

export default Image;
