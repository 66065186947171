import React from "react";

const Image = (props) => {
  return (
    <div className="packageImages">
      <div className="beforeImage">
        <span className="imageTitle">{props.beforeTitle}</span>
        <img src={props.beforeImage} alt="" className="packageImage" />
        <span className="imageDesc">{props.beforeDescription}</span>
      </div>
      <div className="afterImage">
        <span className="imageTitle">{props.afterTitle}</span>
        <img src={props.afterImage} alt="" className="packageImage" />
        <span className="imageDesc">{props.afterDescription}</span>
      </div>
    </div>
  );
};

export default Image;
