import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";

const Navbar = (props) => {
  let className =
    props.page !== "homepage"
      ? {
          backgroundColor: "black",
          color: "white",
        }
      : {
          backgroundColor: "black",
          color: "white",
        };

  const navBarStyle = {
    textDecoration: "none",
    color: "white",
    padding: "0.5rem 2rem",
  };

  return (
    <div>
      <nav style={className} className="navbar navbar-expand-lg navbar-light">
        <NavLink style={navBarStyle} className="navbar-brand" to="/">
          Drone Mapping
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink
              style={navBarStyle}
              component={Link}
              className="nav-item nav-link"
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              style={navBarStyle}
              component={Link}
              className="nav-item nav-link"
              to="/get-quote"
            >
              Get Quote
            </NavLink>
            <NavLink
              style={navBarStyle}
              component={Link}
              className="nav-item nav-link"
              to="/sample-drone-maps"
            >
              Sample Drone Maps
            </NavLink>
            <NavLink
              style={navBarStyle}
              component={Link}
              className="nav-item nav-link"
              to="/google-imagery"
            >
              Update Google Maps
            </NavLink>
            <NavLink
              style={navBarStyle}
              component={Link}
              className="nav-item nav-link"
              to="/faqs"
            >
              FAQs
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
