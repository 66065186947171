import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import GetQuoteButton from "../homepage/components/GetQuoteButton";
import Header from "../homepage/components/Header";
import Card from "./components/Card";
import Image from "./components/Image";

const PackageOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header
        title="Boost Your Business"
        description={
          <>
            Statistics show that 80% of potential customers first check your
            property location, infrastructure, landscaping, etc on Google Maps
            before visiting. <br />
            Google does not update it's map regularly and when it does, it
            updates it with low resolution images taken from satellites.
            <br />
            Old, outdated and low resolution image of your property on Google
            Maps leads to a huge loss in revenue
          </>
        }
      />
      <GetQuoteButton />
      <div className="packageOneContainer">
        <div className="packageOneWrapper">
          <span className="packageOneTitle">THIS IS WHERE WE COME IN.</span>
          <div className="packageContentContainer">
            <div className="packageContentWrapper">
              <span className="packageContentText">
                <ul>
                  <li>
                    Using the latest UAV technology, we update your location on
                    Google Maps for you and make it possible for you to easily
                    share it on your website or any other platform. It is an
                    easy and inexpensive way of showcasing your infrastructure
                    and attracting more clients.
                  </li>
                  <li>
                    We provide a 3D model of your place of business which can be
                    uploaded on your website for clients to see.
                  </li>
                </ul>
              </span>
            </div>
          </div>

          <span className="title">OUR WORK</span>
          <div className="packageImagesContainer">
            <div className="packageImagesWrapper">
              <Image
                beforeTitle={"CURRENT VIEW ON GOOGLE MAPS"}
                beforeImage={"/packages-imgs/kshemavana-before.png"}
                beforeDescription={
                  "(Latest Google Maps Which was Updated in 2021)"
                }
                afterTitle={"BUT THE REALITY IS THIS"}
                afterImage={"/packages-imgs/kshemavana-after.jpeg"}
                afterDescription={"(After our aerial mapping)"}
              />
              <hr className="cardDivider" />
              <Image
                beforeTitle={"CURRENT VIEW ON GOOGLE MAPS"}
                beforeImage={"/packages-imgs/adiyogi-before.png"}
                beforeDescription={
                  "(Latest Google Maps Which was updated in 2021)"
                }
                afterTitle={"BUT THE REALITY IS THIS"}
                afterImage={"/packages-imgs/adiyogi-after.jpeg"}
                afterDescription={"(After our aerial mapping)"}
              />
            </div>
          </div>

          <div className="Card">
            <div className="cardContainer">
              <Card
                image={"/packages-imgs/highres.png"}
                title={"High Resolution Image"}
                description={
                  "We also provide an ultra high resolution image that can be used on large bill boards,and also in print and digital media as advertisements"
                }
              />
              <Card
                image={"/packages-imgs/uptodate.jpg"}
                title={"Up-to-date Map"}
                description={
                  "We provide the latest up-to-date map of your place of business which can be used to showcase your features and facilites to all your potential customers"
                }
              />
              <Card
                image={"/packages-imgs/quickdelivery.png"}
                title={"Timely Delivery"}
                description={
                  "We know how crucial every day is for a business, hence we work to getting you the best output in the fastest possible time to prevent you loosing any potential customers"
                }
              />
            </div>
          </div>
          <a href="https://wa.link/l0bxe5">
            <button className="contactUs">Whatsapp us now!</button>
          </a>
          <NavLink component={Link} to={"/"}>
            <button className="viewOthers">View Our Other Plans</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PackageOne;
