import React from "react";

const GetQuoteButton = () => {
  return (
    <>
      {/* <a href="https://wa.link/zgliik"> */}
      <a href="https://wa.link/ll7g4o">
        <button className="getQuoteButton">
          <div>GET FREE QUOTE</div>
          <span style={{ fontSize: "12px" }}>*Fly now Pay Later</span>
        </button>
      </a>
    </>
  );
};

export default GetQuoteButton;
